@import "../../../styles/index.scss";

.card{
    border-radius: 6px;
    border: 1px solid #DDD;    
    background: #FFF;  
    // border: 1px solid red;
    // display: inline-flex;
    width:90%;
    padding: .5rem;
  
    
}
.card_border{
    border-radius: 6px;
    border: 1px solid #DDD;    
    // background: #FFF;  
    // border: 1px solid red;
    // display: inline-flex;
    width:90%;
    padding: .5rem;
    background: #FDDEEF;
}
.line1_wrapper{
    display: flex;
    align-items: center;
    // justify-content: space-evenly;
    gap: 1rem;
    // border: 1px solid red;
    min-width: auto!important;
    
}
.preferred_text{
    
    background-color: $--color-text-tertiary;
    padding: .3rem;
    font-size: 10px;
    color: $--color-bg-white;
    font-weight: $--font-weight-medium;
    border-radius: .2rem;
    // display: inline;
    // flex: 1;
    // align-items: center;

}

.circle_background{
    // width: 1.938rem;
    // height: 1.938rem;

    // border: 1px solid red;
    margin-left: 1.2rem;
    border-radius: 50%;
      width: 2.2rem;
    height: 2.2rem;
    display: flex;
    background-color: #E1E4FC;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.word_break{
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
    overflow-wrap: break-word;
}
.bold{
    font-weight: 600;
}
.text_prop{
    display: flex;
    flex-wrap: wrap;
    word-break: break-word;
}
.address_label{
    font-weight: 600;
    margin-bottom: .5rem;
}
@media screen and (max-width:500px) {
    .text_prop{
        font-size: $--font-size-xxs;
        font-weight: 400;
    }
    .address_label{
        font-size:$--font-size-xs;
    }
    .word_break{
       font-size: .75rem;
     }
     .icon{
        width: 1rem;
     }
}